<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>意见反馈</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="suggestion_main-box">
      <div class="clearfix table-tools">
        <!-- <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>
              <a-button icon="eye" type="primary" @click="multiItem">批量阅读</a-button>
            </a-form-item>
          </a-form>
        </div> -->
        <div class="search">
          <a-form layout='inline'>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>反馈校区</span>
                </template>
                <a-select allowClear showSearch @search='handleSearch' :filterOption="filterOption" v-model="searchData.studio_id" placeholder="请选择反馈校区" style="width: 200px">
                  <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>阅读情况</span>
                </template>
                <a-select allowClear v-model="searchData.is_read" placeholder="阅读情况" style="width: 140px">
                  <a-select-option v-for="(d, index) of isRead" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>反馈时间</span>
                </template>
                <a-range-picker style="width:280px" :ranges="rangesData" :disabled-date="disabledDate" :allowClear="false" allowClear v-model="searchData.created_at"
                  :placeholder="['开始时间', '结束时间']" />
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div
        class="suggestion-box"
        :style="{ height:wHeight}"
        @scroll="handleScroll"
        v-infinite-scroll="handleInfiniteOnLoad"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10"
      >
        <a-list item-layout="horizontal" :data-source="list">
            <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                <div class="suggestion-item">
                    <div class="suggestion-item-title">
                        <img :src="item.student_avatar">
                        <div>
                            <div>
                                <span>{{item.student_name}}</span>
                                <span style="color:#ccc;font-size:12px;margin-left:5px">{{item.is_read === 0?'未读':'已读'}}</span>
                            </div>
                            <div>
                                <span style="color:#ccc;font-size:12px">{{item.created_at}}</span>
                            </div>
                        </div>
                    </div>
                    <div>{{item.content}}</div>
                    <div v-if="item.suggestion_images.length>0" class="suggestion-item-img suggestion-item-bottom">
                        <LImg preview="0" v-for="(image, index) of item.suggestion_images" :key="index" :src="image" />
                    </div>
                    <div style="margin-top:5px">
                      <a-tag class="suggestion-tag">{{item.studio_id}}</a-tag>
                      <a-tag class="suggestion-tag">{{item.suggestion_channel}}</a-tag>
                    </div>
                </div>
            </a-list-item>
            <div slot="footer">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
            </div>
        </a-list>
      </div>
    </div>
  </div>
</template>

<script>

import C_ITEMS from '@/utils/items'
import moment from 'moment'
import infiniteScroll from 'vue-infinite-scroll'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },
  components: {
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams: {
        "page": 0,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,
      isRead: C_ITEMS.isRead,
      studios: [],
      channels: [],
      parentChannels:[],
      selectedRowKeys: [],
      searchData: {},
      authType:['orginization','suggestion'],
      visible: false,
      refundVisible: false,

      myHeight:0,
      busy:false,

    }
  },
  mixins: [ ranges , authority ],
  computed:{
      wHeight(){
          return `${Number(this.myHeight) - 300}px`
      }
  },
  directives: { infiniteScroll },
  created() {
    this.myHeight = this.windowHeight()
    this.getList()
    this.getStudio()
    this.getSourceChannel()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio(val) {
      let res = await this.$store.dispatch('searchBelongStudioAction', val)
      this.studios = res.data
    },
    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      await this.$store.dispatch('studioSuggestionAction', { data: this.searchParams })
      .then(res=>{
        if(res.items.length >0){
            this.list = [...this.list,...res.items]
        }else{
            this.busy = true
        }
        if(this.searchParams.page == 1){
            if(this.list.length > 0){
                this.timers = setTimeout(()=>{
                    this.$nextTick(()=>{
                        if(this.$refs.journalItem){
                            let arr = this.$refs.journalItem.$parent.$children
                            arr.forEach((item,index)=>{
                                if(item.$el.offsetTop < (Number(this.myHeight) - 150)){
                                    if(this.list[index].is_read === 0){
                                        this.toSeen(index)
                                    }
                                }
                            })
                        }
                    })
                },2000)
            }
        }
        this.loading = false
      })
      .catch(err=>{
        this.loading = false
      })
      
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    changeStatus(record) {
      if (record.is_read === 0) {
        this.readItem(record)
      }
    },
    readItem(item) {
      let that = this
      return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('studioSuggestionReadAction', { data: { id: item.id } })
            if (res) {
              that.$message.success('操作成功!');
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error));
    },
    multiItem() {
      if (this.selectedRowKeys.length <= 0) {
        return 
      }
      let that = this
      this.$confirm({
        title: `您确定要操作选中项吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('studioSuggestionMultiActionAction', { data: { selection: that.selectedRowKeys } })
            if (res) {
              that.$message.success('操作成功!');
              that.selectedRowKeys = []
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    onChangeCascader(value, selectedOptions) {
      if(value.length != 0){
        this.searchData.channel_id = value[1];
      }else{
        this.searchData.channel_id = '';
      }
    },
    handleSearch(e){
      console.log(e)
      this.getStudio({q:e})
    },

    handleScroll(e){
        if(e.target.scrollTop < this.minHeight) return false
        this.minHeight += 250
        let scrollTop = Number(e.target.scrollTop) + Number(e.target.offsetHeight)
        let arr = this.$refs.journalItem.$parent.$children
        let top = []
        arr.forEach((item,index)=>{
            if(item.$el.offsetTop < scrollTop){
                if(this.list[index].is_read === 0){
                    this.toSeen(index)
                }
            }
        })
    },
    handleInfiniteOnLoad(){
        if(this.searchParams.page != 0){
            if(!this.busy){
                this.getList()
            }
        }
        this.searchParams.page++
    },
    windowHeight() { 
        var de = document.documentElement
        return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
    },
    searchList(){
      this.searchParams.page = 1
      this.list = []
      this.busy = false
      this.getList()
    },
    async toSeen(index){
        await this.$store.dispatch('studioSuggestionReadAction', {data:{id:this.list[index].id}})
        .then(res=>{
            this.list[index].is_read = 1
        })
    },


  }
}
</script>

<style lang="scss" scoped>
  .suggestion-tag{
    color: #999 !important;
    border-color: #f5f7fa !important;
    border-bottom-left-radius: 11px;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;

  }
  .suggestion_main-box{
      width: 63%;
      padding:15px;
      background: #fff;
  }
  .suggestion-box{
    overflow-y: scroll;
    .suggestion-item{
        border: 1px solid #e8e8e8;
        padding: 10px;
        width: 700px;
        border-radius: 3px;
        position: relative;
        .suggestion-item-title{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            img{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
        .suggestion-item-img{
            img{
                max-width: 80px;
                max-height: 60px;
                margin: 5px;
            }
        }
        .suggestion-item-customer{
            padding: 8px;
            background-color: #f5f7fa;
            border-radius: 2px;
            position: relative;
            margin-bottom: 5px;
            color: #3e84e9;
            cursor: pointer;
        }
        .suggestion-item-bottom{
            margin-bottom: 10px;
        }
        .suggestion-item-more{
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
        .suggestion-item-comments{
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            .ant-input{
                resize: none;
                border: none;
            }
            .ant-input:focus{
                box-shadow:none;
            }
            .suggestion-item-comments-btn{
                text-align: right;
                margin-right: 11px;
                margin-bottom: 4px;
            }
        }
        .suggestion-comments-item{
            .suggestion-comments-wrap{
                .suggestion-comments-wrap-nav{
                    display: flex;
                    align-items: center;
                    img{
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                }
                .suggestion-comments-item-icon{
                    cursor: pointer;
                }
                .suggestion-comments-item-hover:hover{
                    color: #00cca2;
                }
            }
        }
        .suggestion-comments-wrap-content{
            padding: 15px 30px;
        }
        .suggestion-item-comments-answer{
            text-align: right;
            padding: 5px;
            background: #ccc;
        }
    }
    .ant-list-item{
        border: none;
        border-bottom: none !important;
    }
  }
  .suggestion-more{
      text-align: center;
      padding: 10px 0;
  }
</style>